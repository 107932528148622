<template>
  <div class="home">
    <Jumbotron class="jumbotron-fluid about">
      <div id="textHeader" class="row justify-content-center align-self-center">
        <div class="col-lg text-center">
          <section>
            <header>
              <h2 class="bitter-bold margin-bottom-40 white">
                Hello, my name is Rick Zawadzki.
              </h2>
              <h3 class="bitter-bold magin-bottom-40 line-height-36 white">
                  I'm a <strong>Project Manager</strong> and <strong>User Experience
                  Developer</strong> based out of Central Florida.
              </h3>
            </header>
            <p class="white">
              My experiences cover all phases of the application design process- from
              ideation to deployment. I have many skills that run the gamut from
              prototyping, user experience design, front and backend development,
              and project management. In my freetime I work on staying fit, run obstacle
              course races, and indulge my explorer spirit.
            </p>
          </section>
        </div>
      </div>
    </Jumbotron>
    <Jumbotron class="jumbotron-fluid pullquote">
        <Pullquote>
          <p slot="quote">
            "He is <strong>fantastic</strong> when it
            comes to creating anything from a <strong>new and innovative</strong> design to an
            entirely new user experience."
          </p>
          <span slot="source">Steve Gongage</span>
        </Pullquote>
      </Jumbotron>
      <Jumbotron class="jumbotron-fluid work">
        <div class="row justify-content-center align-self-center">
          <div class="col-lg text-center">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a href="/#/project/2">
                  <img alt="Osceola County &amp; Related Sites"
                    src="/assets/osceola/thumb_OsceolaOrg_354x323.jpg"
                    width="354" height="323"
                    v-tooltip:bottom="'Osceola County &amp; Related Sites'"
                  />
                </a>
              </li>
              <li class="list-inline-item">
                <a href="/#/project/1">
                  <img alt="Experience Kissimmee - Miles Partnership"
                    src="/assets/miles/thumb_ExperienceKissimmee_354x323.jpg"
                    width="354" height="323"
                    v-tooltip:bottom="'Experience Kissimmee - Miles Partnership'"
                  />
                </a>
              </li>
              <li class="list-inline-item">
                <a href="/#/project/3">
                  <img alt="Social Patient Center (SPC) - Crystal Clear Digital Marketing"
                    src="/assets/ccdm/thumb_SPC_354x323.jpg"
                    title="Social Patient Center (SPC) - Crystal Clear Digital Marketing"
                    width="354" height="323"
                  v-tooltip:bottom="'Social Patient Center (SPC) - Crystal Clear Digital Marketing'"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Jumbotron>
  </div>
</template>

<script>
// @ is an alias to /src
import Jumbotron from '@/components/Jumbotron.vue';
import Pullquote from '@/components/Pullquote.vue';
import Vue from 'vue';
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

Vue.use(Tooltip);

export default {
  name: 'Home',
  components: {
    Jumbotron,
    Pullquote,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.amatic {
  text-transform: capitalize;
  font: 1rem "Amatic SC", cursive;
}

.white {
  color: #fff!important;
}

.work {
  background-color: #30353d;
}

.about {
  /*left: -10px;
  opacity: 0;*/
  left: 0;
  opacity: 1;
  position: relative;
  background: url('~@/assets/img/bkg_hexpattern_81x600.png') repeat-x rgb(10, 46, 92);
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  height: 33.6rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0a2e5c;
    background-image: -webkit-linear-gradient(top left, #0a2e5c, #fd261f);
    background-image: linear-gradient(to bottom right,#0a2e5c, #fd261f);
    opacity: .20;
    width: 100%;
    /*height: 33.625rem;*/
  }

  #textHeader {
    opacity: 0;
    position: relative;
    left: -10px;
    opacity: 1;
    transform: translateX(10px);

    .text-header {
      width: 18.125rem;
      margin-top: 2.625rem;
      min-height: 274px;

      .amatic {
        color: rgb(253, 38, 31);
        font-size: 3rem;
        width: 100%;

        &.adventurous {
          top: 0px;
          text-align: left;
          position: relative;
          z-index: 1;
        }

        &.design {
          position: relative;
          top: -7.125rem;
          text-align: right;
        }

        .top-line {
          position: absolute;
          top: 1.75rem;
          z-index: 2;
          right: 0px;
          width: 6.25rem;
          height: 6px;
          background: url('//www.rickzawadzki.com/img/pencil-line.png') left center repeat-x;
        }

        .bottom-line {
          position: absolute;
          top: 1.75rem;
          z-index: 2;
          left: 0px;
          width: 11.125rem;
          height: 6px;
          background: url('//www.rickzawadzki.com/img/pencil-line.png') left center repeat-x;
        }
      }

      .bitter-bold {
        color: rgb(255, 255, 255);
        font-size: 11.786rem;
        position: relative;
        top: -4.5rem;
      }
    }
  }
}
</style>
