<template>
  <div id="app" projects="this.$store.projects">
    <div id="nav">
      <header class="container">
        <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a class="navbar-brand" href="#">
            <img alt="RZ - Rick Zawadzki" src="./assets/img/rz-logotype.svg"
              width="40px" height="40px" />
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarCollapse" aria-controls="navbarCollapse"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mr-auto list-inline">
              <li class="nav-item active list-inline-item">
                <router-link to="/" class="btn">Home</router-link>
              </li>
              <li class="nav-item list-inline-item">
                <router-link to="/about" class="btn">About</router-link>
              </li>
              <li class="nav-item list-inline-item">
                <div class="dropdown show">
                  <a class="btn dropdown-toggle" href="#"
                    role="button" id="projectsMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Projects
                  </a>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <router-link v-for="project in projects" :key="project.id"
                      :to="project.routeUrl" class="dropdown-item">
                        {{ project.title }}
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="nav-item active list-inline-item">
                <router-link to="/resume" class="btn">Resume</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
    <main role="main" class="container-fluid">
      <router-view :projects="projects" :activeProject="activeProject" :resume="resume" />
      <Jumbotron class="jumbotron-fluid contact">
        <ul class="list-inline d-flex justify-content-center">
          <li class="list-inline-item padding-left-20 padding-right-20">
            <a href="https://www.linkedin.com/in/rickzawadzki/">LinkedIn</a>
          </li>
          <li class="list-inline-item padding-left-20 padding-right-20">
            <a href="https://www.instagram.com/heywrecker/">Instagram</a>
          </li>
          <li class="list-inline-item padding-left-20 padding-right-20">
            <a href="mailto:rickzawadzki@gmail.com  ">Email</a>
          </li>
        </ul>
      </Jumbotron>
    </main>
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Jumbotron from '@/components/Jumbotron.vue';

export default {
  name: 'portfolio',
  components: {
    Jumbotron,
  },
  computed: mapState([
    'projects',
    'activeProject',
    'resume',
  ]),
  mounted() {
    if (this.projects.length === 0) {
      this.$http
        .get('//www.rickzawadzki.com/assets/portfolio_data.json')
        .then((response) => {
          this.$store.commit('UPDATE_PROJECTS', response);
        })
        .catch(() => {});
    }

    this.$http
      .get('//www.rickzawadzki.com/assets/resume_data.json')
      .then((response) => {
        this.$store.commit('UPDATE_RESUME', response);
      })
      .catch(() => {});
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

#nav {
  padding: 30px;
  .nav-item {
    margin-right: 20px;
  }
  a {
    font-weight: bold;
    color: #ffffff;

    &.router-link-exact-active {
      color: #ffffff;
      text-decoration: underline;
    }

    &.dropdown-item {
      color: #000000;

      &:focus, &:hover {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
        text-decoration: underline;
      }
    }
  }
}

.footer {
  /*position: absolute;*/
  /*bottom: 0;*/
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.line-height-36 {
  line-height: 36px!important;
}

h1 {
  &.bitter-bold {
    color: #0a2e5c;
    font-size: 42px!important;
  }
}

h2 {
  &.bitter-bold {
    color: #686868;
    font-size: 36px!important;
  }
}

h3 {
  &.bitter-bold {
    color: #989898;
    font-size: 24px!important;
  }
}

.bitter-bold {
  /*text-transform: capitalize;*/
  font: 700 1rem Bitter, serif;
}

p {
  font-size: 18px;
  margin-bottom: 40px;
}

ul {
  font-size: 18px;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.padding-left-20 {
  padding-left: 20px!important;
}

.padding-right-20 {
  padding-right: 20px!important;
}

.font-18 {
  font-size: 18px;
}
</style>
