import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: [],
    activeProject: {},
    resume: {},
  },
  mutations: {
    UPDATE_ACTIVE_PROJECT(state, selectedProject) {
      state.activeProject = selectedProject;
    },
    UPDATE_PROJECTS(state, projects) {
      console.log('UPDATING PROJECTS');
      state.projects = projects.data;
    },
    UPDATE_RESUME(state, resume) {
      state.resume = resume.data;
    },
  },
  modules: {
  },
});
