<template>
  <div class="jumbotron">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Jumbotron',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .jumbotron {
    &.pullquote {
      margin-bottom: 0;
      padding: 1em 2em;
      background-color: #252525;
    }

    &.contact {
      background-color: #fff;
    }

    &.about {
      > div {
        &.container {
          height: 100%!important;
          display: -webkit-box!important;
          display: -ms-flexbox!important;
          display: flex!important;
        }
      }
    }
  }
</style>
